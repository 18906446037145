<template>
  <div class="container">
    <Breadcrumb :breadcrumbList="[ { 'name': 'Admin Dashboard', 'link': '/admin' },{ 'name': 'Featured Tasks' } ]"/>
    <div class="main-container">
      <h1 class="font-weight-bold">Featured Tasks</h1>
      <div class="content-box container">
        <div class="box-title border-bottom">
          <h2>Add Featured Tasks</h2>
        </div>
        <form @submit.prevent="getTasks(1)" class="p-3">
          <b-input-group>
            <b-form-input type="text" v-model="task.term" ref="searchInput" :placeholder="$t('find_service')"/>
            <b-input-group-append>
              <b-button variant="primary" size="sm" type="submit">
                <span><i class="fa-solid fa-magnifying-glass"></i></span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </form>
        <div v-if="task.display">
          <div v-if="Array.isArray(task.results) === false" class="col">
            <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"/>
            <div class="mb-5">{{ $t('loading_tasks') }}</div>
          </div>
          <div v-else-if="task.results.length === 0">
            <div class="p-3 mb-2">Sorry we was unable to find any tasks with that search result.</div>
          </div>
          <div v-else>
            <div class="box-title border-bottom">
              <h2>
                {{ paginationTitle }}
              </h2>
            </div>
            <table class="table table-striped table-bordered mb-3 mt-3">
              <thead>
              <th>Username</th>
              <th>Title</th>
              <th>Categories</th>
              <th>Actions</th>
              </thead>
              <tbody class="text-left">
              <tr v-for="(task, index) in task.results" :key="index">
                <td>{{ task.user.username }}</td>
                <td>{{ task.title }}</td>
                <td class="show-line-style">
                  <ul class="pl-3">
                    <li v-for="(category, idx) in task.categories" :key="idx">
                      {{ category.parent_category.name }} &raquo; {{ category.name }}
                    </li>
                  </ul>
                </td>
                <td class="text-center">
                  <router-link :to="'/' + task.user.username + '/' + task.slug" target="_blank" class="text-dark" title="Preview the task (this open in a new window)"><i class="fa-solid fa-magnifying-glass"></i></router-link>
                  <a @click="addTask(task)" class="text-success pl-2" title="Add task to featured list" v-if="!taskExist(task, currentList) && !taskExist(task, addedTasks)"><i
                    class="fa-solid fa-lg fa-plus"/></a>
                  <span v-else class="text-muted pl-2"><i class="fa-solid fa-lg fa-plus"/></span>
                </td>
              </tr>
              </tbody>
            </table>
            <Pagination :perPage="task.page.perPage" :currentPage="task.page.current" :total="task.page.total" v-on:paginationUpdate="taskNextPage"
                        v-if="task.page.total > task.page.perPage"/>
          </div>
        </div>
      </div>
      <div class="content-box container">
        <div class="box-title border-bottom">
          <h2>Current Featured Tasks</h2>
        </div>
        <div class="row mt-3 mb-3">
          <div v-if="Array.isArray(currentList) === false" class="col">
            <div>
              <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"/>
              <div class="mb-5">Loading Featured Tasks</div>
            </div>
          </div>
          <div v-else-if="currentList.length === 0" class="col-12 text-center">
            Please search for a task to add a featured task
          </div>
          <draggable v-else tag="span" v-model="currentList" v-bind="dragOptions" @update="taskMoved" class="col-12 draggable">
            <transition-group name="feature-list" class="list-group text-left" tag="ul">
              <li class="list-group-item" v-for="(task, idx) in currentList" :key="task.hash" v-bind:class="{'bg-light': taskExist(task, addedTasks)}">
                <div class="row align-items-center">
                  <div class="col-10">
                    <div class="small text-secondary font-italic">{{ task.user.username }}</div>
                    <span>{{ task.title }}</span>
                  </div>
                  <div class="col-2 text-right">
                    <i class="fa-solid fa-arrows-up-down-left-right" title="Click and drag to re-order this list"/>
                    <i @click="deleteFeaturedTask(task, idx)" title="Remove this task from featured list" class="fa-solid fa-trash-can text-danger ml-2"/>
                  </div>
                </div>
              </li>
            </transition-group>
            <div slot="footer" class="row justify-content-end pr-3">
              <button slot="footer" v-if="preloader.patch" class="btn btn-outline-dark mb-3 mt-3">
                <b-spinner small/>
                Update Sort
              </button>
              <button slot="footer" v-else @click="updateSortFeaturedTask" class="btn btn-outline-dark mb-3 mt-3" :disabled="!sortChanged">Update Sort</button>
              <button slot="footer" class="btn btn-primary mb-3 mt-3 ml-2" v-if="preloader.created">
                <b-spinner small/>
                Save new tasks
              </button>
              <button slot="footer" @click="createFeaturedTasks" class="btn btn-primary mb-3 mt-3 ml-2" v-else-if="!preloader.created" :disabled="addedTasks.length === 0">Save new
                tasks
              </button>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import apiService from '../../apiService.js'
import Pagination from '../../components/Global/Pagination'
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'

export default {
  name: 'AdminFeatured',
  components: {
    draggable,
    Breadcrumb,
    Pagination
  },
  data () {
    return {
      addedTasks: [],
      currentList: null,
      preloader: {
        patch: false,
        created: false
      },
      sortChanged: false,
      task: {
        display: false,
        results: null,
        term: null,
        page: {
          current: 1,
          perPage: 10,
          total: 0
        }
      }
    }
  },
  mounted () {
    this.getFeaturedTasks()
  },
  methods: {
    getTasks (page = null) {
      if (page !== null) {
        this.task.page.current = page
      }

      if (this.task.term === null) {
        this.task.term = ''
      }

      this.task.results = null
      this.task.display = true

      apiService.getTasksAdmin(this.task.page.perPage, this.task.page.current, 1, this.task.term, 0).then(response => {
        this.task.results = response.data.data.results
        this.task.page.current = response.data.data.pagination.page
        this.task.page.perPage = response.data.data.pagination.per_page
        this.task.page.total = response.data.data.pagination.total
      }).catch(() => {
      })
    },
    taskNextPage (page = this.currentPage) {
      this.task.page.current = page
      this.getTasks()
    },
    taskMoved () {
      this.sortChanged = true
    },
    addTask (task) {
      if (!this.taskExist(task, this.currentList)) {
        this.addedTasks.push(task)
        this.currentList.push(task)
        this.$bvToast.toast('Has been added to the list: ' + task.title, {
          title: 'New item (scroll to bottom to save)',
          toaster: 'b-toaster-bottom-right',
          autoHideDelay: 3000
        })
      } else {
        this.$bvToast.toast('This task is already in the featured list ' + task.title, {
          title: 'Error',
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
          autoHideDelay: 3000
        })
      }
    },
    taskExist (task, compareWith) {
      return compareWith.filter(function (e) {
        return e.hash === task.hash
      }).length > 0
    },
    getFeaturedTasks () {
      this.currentList = null
      apiService.getFeaturedTasks().then(response => {
        this.currentList = response.data.data
      }).catch()
    },
    createFeaturedTasks () {
      if (this.addedTasks.length) {
        this.preloader.created = true
        apiService.postFeaturedTask(this.getFeatureAddedHashes).then(response => {
          this.addedTasks = []
          this.preloader.created = false
          this.$swal(
            'Feature been updated',
            'The new featured has been saved',
            'success'
          )
        }).catch((error) => {
          this.preloader.created = false
          this.$swal(
            'Failed to save',
            Object.values(error.response.data.errors.children.items).join(' '),
            'error'
          )
        })
      }
    },
    deleteFeaturedTask (task, index) {
      if (this.taskExist(task, this.currentList)) {
        this.$swal({
          title: 'Are you sure?',
          text: 'Do you wish to remove "' + task.title + '" from the featured list?',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            if (this.taskExist(task, this.addedTasks)) {
              this.currentList.splice(index, 1)
              this.addedTasks.splice(this.addedTasks.indexOf(task), 1)
              return true
            } else {
              return apiService.deleteFeaturedTask(task.hash).then(response => {
                this.currentList.splice(index, 1)
              }).catch(error => {
                this.$swal.showValidationMessage(
                  error.response.data.error_message
                )
              })
            }
          }
        }).then((result) => {
          if (result.value) {
            this.$swal(
              'Deleted',
              'The feature task has been removed.',
              'success'
            )
          }
        })
      }
    },
    updateSortFeaturedTask () {
      if (this.addedTasks.length > 0) {
        this.$swal(
          'Warning',
          'You have unsaved tasks please save before updating the sort.',
          'warning'
        )
      } else {
        this.preloader.patch = true
        apiService.patchFeaturedTask(this.getFeatureHashes).then(response => {
          this.preloader.patch = false
          this.sortChanged = false
          this.$swal(
            'Feature been updated',
            'The featured sort has been updated',
            'success'
          )
        }).catch(error => {
          this.preloader.patch = false
          this.$swal(
            'Failed to save',
            Object.values(error.response.data.errors.children.items).join(' '),
            'error'
          )
        })
      }
    }
  },
  computed: {
    dragOptions () {
      return {
        ghostClass: 'ghost'
      }
    },
    paginationTitle () {
      const currentTotalPosition = this.task.page.perPage * (this.task.page.current - 1)
      return this.$t('showing_tasks', { page_low_range: currentTotalPosition + 1, page_total: this.task.results.length + currentTotalPosition, total: this.task.page.total })
    },
    getFeatureHashes () {
      return this.currentList.map(a => a.hash)
    },
    getFeatureAddedHashes () {
      return this.addedTasks.map(a => a.hash)
    }
  }
}
</script>
<style scoped>
.draggable .list-group-item {
  cursor: move;
}

.draggable .list-group-item i {
  cursor: pointer;
}

.draggable .ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.show-line-style ul li {
  list-style-type: circle;
}
</style>
<style>
#b-toaster-bottom-right {
  z-index: 999999;
}
</style>
